// Important:
// Avoid using libraries in loader.js to prevent additional initial script tags from bundling

function shouldTrackConversion() {
  let ovData = window.OnVoardData || {};
  let platform = ovData.ecommerce_platform;

  // OnVoardData is not loaded on Shopify conversion page
  if (window.Shopify) {
    if (!window.Shopify.checkout) return false;
    return true;
  }

  if (platform === 'bigcommerce') {
    if (ovData.page_type !== "orderconfirmation") return false;
    return true;
  }

  if (platform === 'easystore') {
    if (ovData.template !== "payment_completed") return false;
    return true;
  }

  if (platform === 'shopline') {
    let preloadData = window.__PRELOAD_STATE__ || {};
    if (!preloadData || preloadData.templateAlias.toLocaleLowerCase() !== 'thankyou') return false;
    return true;
  }

  if (platform === 'shopware') {
    if (ovData.controller_name !== "Checkout" || ovData.active_route !== "frontend.checkout.finish.page") return false;
    return true;
  }

  if (platform === 'wix') {
    if (!document.querySelector("[data-hook='ThankYouPageAppDataHook.root']")) return false;
    return true;
  }

  if (platform === 'woocommerce') {
    if (ovData.page_type !== "thank-you") return false;
    return true;
  }

  return false;
}


function renameBackInStockButtons() {
  let els = document.querySelectorAll(".ov-app-back-in-stock-button");
  els.forEach((el) => {
    el.classList.remove("ov-app-back-in-stock-button");
    el.classList.add("ov-app-back-in-stock-inline-button");
    el.setAttribute("data-app", "back-in-stock-inline-button");
  })

  els = document.querySelectorAll(".ov-back-in-stock-button");
  els.forEach((el) => {
    el.classList.remove("ov-back-in-stock-button");
    el.classList.add("ov-back-in-stock-inline-button");
  })
}

// https://stackoverflow.com/a/15724300/1446284
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function checkLoadPlacementTool() {
  const urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get('ov_placement_tool_token');
  if (token) return true;

  token = getCookie("ov_placement_tool_session_token");
  if (token) return true;
  return false;
}

function loadPlacementToolScripts() {
  const loadPlacementTool = checkLoadPlacementTool();
  if (loadPlacementTool) {
    var exists = document.querySelectorAll("#ov_app_placement_tool").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_placement_tool";
      document.body.appendChild(div);
    }

    import('placement-tool/src/entries/index')
    .then((app) => {
      app.load();
    });
  }
}

function loadReviewsWidgetScripts() {
  const loadReviewsWidget = document.querySelectorAll(".ov-app-reviews-widget").length > 0;
  if (loadReviewsWidget) {
    var exists = document.querySelectorAll("#ov_app_reviews_widget").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_reviews_widget";
      document.body.appendChild(div);
    }

    import('reviews/widget/src/entries/index')
    .then((app) => {
      app.load();
    });
  }
}

function loadStarRatingScripts() {
  const loadStarRating = document.querySelectorAll(".ov-app-star-rating").length > 0;
  if (loadStarRating) {
    var exists = document.querySelectorAll("#ov_app_star_rating").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_star_rating";
      document.body.appendChild(div);
    }

    import('reviews/star-rating/src/entries/index')
    .then((app) => {
      app.load();
    });
  }
}


function loadReperksWidgetScripts() {
  const loadReperksWidget = document.querySelectorAll(".ov-app-reperks-widget").length > 0;
  if (loadReperksWidget) {
    var exists = document.querySelectorAll("#ov_app_reperks_widget").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_reperks_widget";
      document.body.appendChild(div);
    }

    import('reperks/widget/src/entries/index')
    .then((app) => {
      app.load();
    });
  }
}

function loadAovProgressBarScripts() {
  const loadAovProgressBar = document.querySelectorAll(".ov-app-aov-progress-bar").length > 0;
  if (loadAovProgressBar) {
    var exists = document.querySelectorAll("#ov_app_aov_progress_bar").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_aov_progress_bar";
      document.body.appendChild(div);
    }

    import('aov-progress-bar/src/entries/index')
    .then((app) => {
      app.load();
    });
  }
}

function loadRecommenderScripts() {
  const loadRecommender = document.querySelectorAll(".ov-app-recommender").length > 0;

  if (loadRecommender) {
    var exists = document.querySelectorAll("#ov_app_recommender").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_recommender";
      document.body.appendChild(div);
    }

    import('recommender/src/entries/index')
    .then((app) => {
      app.load();
    });
  }
}

function loadPromptScripts() {
  const loadPrompt = document.querySelectorAll(".ov-app-prompt").length > 0;

  if (loadPrompt) {
    var exists = document.querySelectorAll("#ov_app_prompt").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_prompt";
      document.body.appendChild(div);
    }

    import('prompt/src/entries/index')
    .then((app) => {
      app.load();
    });
  }
}

function loadBackInStockScripts() {
  const loadBackInStock = document.querySelectorAll(".ov-app-back-in-stock").length > 0;
  const loadBackInStockFloatingButton = document.querySelectorAll(".ov-app-back-in-stock-floating-button").length > 0;
  const loadBackInStockInlineButton = document.querySelectorAll(".ov-app-back-in-stock-inline-button").length > 0;
  const loadBackInStockInlineText = document.querySelectorAll(".ov-app-back-in-stock-inline-text").length > 0;
  const loadBackInStockInlineForm = document.querySelectorAll(".ov-app-back-in-stock-inline-form").length > 0;

  if (loadBackInStock) {
    var exists = document.querySelectorAll("#ov_app_back_in_stock").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_back_in_stock";
      document.body.appendChild(div);
    }

    import('back-in-stock/src/entries/index')
    .then((app) => {
      app.load();
    });
  }

  if (loadBackInStockFloatingButton) {
    var exists = document.querySelectorAll("#ov_app_back_in_stock_floating_button").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_back_in_stock_floating_button";
      document.body.appendChild(div);
    }

    import('back-in-stock/floating-button/src/entries/index')
    .then((app) => {
      app.load();
    });
  }

  if (loadBackInStockInlineButton) {
    var exists = document.querySelectorAll("#ov_app_back_in_stock_inline_button").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_back_in_stock_inline_button";
      document.body.appendChild(div);
    }

    import('back-in-stock/inline-button/src/entries/index')
    .then((app) => {
      app.load();
    });
  }

  if (loadBackInStockInlineText) {
    var exists = document.querySelectorAll("#ov_app_back_in_stock_inline_text").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_back_in_stock_inline_text";
      document.body.appendChild(div);
    }

    import('back-in-stock/inline-text/src/entries/index')
    .then((app) => {
      app.load();
    });
  }

  if (loadBackInStockInlineForm) {
    var exists = document.querySelectorAll("#ov_app_back_in_stock_inline_form").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_back_in_stock_inline_form";
      document.body.appendChild(div);
    }

    import('back-in-stock/inline-form/src/entries/index')
    .then((app) => {
      app.load();
    });
  }
}

function loadTrackingScripts() {
  const loadRecommenderTracking = document.querySelectorAll(".ov-app-recommender-tracking").length > 0;
  const loadWebTracking = document.querySelectorAll(".ov-app-web-tracking").length > 0;
  const loadWebTrackingShopify = document.querySelectorAll(".ov-app-web-tracking-shopify").length > 0;
  const loadWebTrackingBigCommerce = document.querySelectorAll(".ov-app-web-tracking-bigcommerce").length > 0;
  const loadWebTrackingEasyStore = document.querySelectorAll(".ov-app-web-tracking-easystore").length > 0;
  const loadWebTrackingShopline = document.querySelectorAll(".ov-app-web-tracking-shopline").length > 0;
  const loadWebTrackingShopware = document.querySelectorAll(".ov-app-web-tracking-shopware").length > 0;
  const loadWebTrackingWix = document.querySelectorAll(".ov-app-web-tracking-wix").length > 0;
  const loadWebTrackingWooCommerce = document.querySelectorAll(".ov-app-web-tracking-woocommerce").length > 0;

  if (loadRecommenderTracking) {
    var exists = document.querySelectorAll("#ov_app_recommender_tracking").length > 0;
    if (!exists) {
      var div = document.createElement("div");
      div.id = "ov_app_recommender_tracking";
      document.body.appendChild(div);
    }

    import('recommender/tracking/src/index')
    .then((app) => {
      app.processTracking();
    });
  }

  if (loadWebTracking) {
    if (!window.OnVoardAddedScripts.webTracking) {
      window.OnVoardAddedScripts.webTracking = true;

      var exists = document.querySelectorAll("#ov_app_web_tracking").length > 0;
      if (!exists) {
        var div = document.createElement("div");
        div.id = "ov_app_web_tracking";
        document.body.appendChild(div);
      }

      import('web-tracking/src/index')
      .then((app) => {
        app.processTracking();
      });
    }
  }

  if (loadWebTrackingShopify) {
    if (!window.OnVoardAddedScripts.webTrackingShopify) {
      window.OnVoardAddedScripts.webTrackingShopify = true;

      var exists = document.querySelectorAll("#ov_app_web_tracking_shopify").length > 0;
      if (!exists) {
        var div = document.createElement("div");
        div.id = "ov_app_web_tracking_shopify";
        document.body.appendChild(div);
      }

      import('web-tracking/shopify/src/index')
      .then((app) => {
        app.processTracking();
      });
    }
  }

  if (loadWebTrackingBigCommerce) {
    if (!window.OnVoardAddedScripts.webTrackingBigCommerce) {
      window.OnVoardAddedScripts.webTrackingBigCommerce = true;

      var exists = document.querySelectorAll("#ov_app_web_tracking_bigcommerce").length > 0;
      if (!exists) {
        var div = document.createElement("div");
        div.id = "ov_app_web_tracking_bigcommerce";
        document.body.appendChild(div);
      }

      import('web-tracking/bigcommerce/src/index')
      .then((app) => {
        app.processTracking();
      });
    }
  }

  if (loadWebTrackingEasyStore) {
    if (!window.OnVoardAddedScripts.webTrackingEasyStore) {
      window.OnVoardAddedScripts.webTrackingEasyStore = true;

      var exists = document.querySelectorAll("#ov_app_web_tracking_easystore").length > 0;
      if (!exists) {
        var div = document.createElement("div");
        div.id = "ov_app_web_tracking_easystore";
        document.body.appendChild(div);
      }

      import('web-tracking/easystore/src/index')
      .then((app) => {
        app.processTracking();
      });
    }
  }

  if (loadWebTrackingShopline) {
    if (!window.OnVoardAddedScripts.webTrackingShopline) {
      window.OnVoardAddedScripts.webTrackingShopline = true;

      var exists = document.querySelectorAll("#ov_app_web_tracking_shopline").length > 0;
      if (!exists) {
        var div = document.createElement("div");
        div.id = "ov_app_web_tracking_shopline";
        document.body.appendChild(div);
      }

      import('web-tracking/shopline/src/index')
      .then((app) => {
        app.processTracking();
      });
    }
  }

  if (loadWebTrackingShopware) {
    if (!window.OnVoardAddedScripts.webTrackingShopware) {
      window.OnVoardAddedScripts.webTrackingShopware = true;

      var exists = document.querySelectorAll("#ov_app_web_tracking_shopware").length > 0;
      if (!exists) {
        var div = document.createElement("div");
        div.id = "ov_app_web_tracking_shopware";
        document.body.appendChild(div);
      }

      import('web-tracking/shopware/src/index')
      .then((app) => {
        app.processTracking();
      });
    }
  }

  if (loadWebTrackingWix) {
    if (!window.OnVoardAddedScripts.webTrackingWix) {
      window.OnVoardAddedScripts.webTrackingWix = true;
      var exists = document.querySelectorAll("#ov_app_web_tracking_wix").length > 0;
      if (!exists) {
        var div = document.createElement("div");
        div.id = "ov_app_web_tracking_wix";
        document.body.appendChild(div);
      }

      import('web-tracking/wix/src/index')
      .then((app) => {
        app.processTracking();
      });
    }
  }

  if (loadWebTrackingWooCommerce) {
    if (!window.OnVoardAddedScripts.webTrackingWooCommerce) {
      window.OnVoardAddedScripts.webTrackingWooCommerce = true;

      var exists = document.querySelectorAll("#ov_app_web_tracking_woocommerce").length > 0;
      if (!exists) {
        var div = document.createElement("div");
        div.id = "ov_app_web_tracking_woocommerce";
        document.body.appendChild(div);
      }

      import('web-tracking/woocommerce/src/index')
      .then((app) => {
        app.processTracking();
      });
    }
  }

  if (shouldTrackConversion()) {
    import('conversion-tracking/src/index')
    .then((app) => {
      app.processTracking();
    });
  }
}


function loadScripts() {
  renameBackInStockButtons();

  loadPlacementToolScripts();
  loadReviewsWidgetScripts();
  loadReperksWidgetScripts();
  loadStarRatingScripts();
  loadAovProgressBarScripts();

  loadRecommenderScripts();
  loadPromptScripts();
  loadBackInStockScripts();
  loadTrackingScripts();
}


function process(args) {
  var params = [].slice.call(args);
  var ns = params.shift();
  var method = params.shift();

  if (!ns || !method) {
    console.log("Invalid namespace or method");
    return;
  }

  if (ns === "loader") {
    if (method === 'reloadScripts') {
      var app = params.shift();
      if (app == 'aov_progress_bar') {
        loadAovProgressBarScripts();
      }
    }
  }

  if (ns === "aov_progress_bar") {
    if (window.OnVoardApi.aovProgressBar) {
      window.OnVoardApi.aovProgressBar(args);
    } else {
      window.OnVoardQueue.aovProgressBar.push(args);
    }
  }

  if (ns === "back_in_stock") {
    if (window.OnVoardApi.backInStock) {
      window.OnVoardApi.backInStock(args);
    } else {
      window.OnVoardQueue.backInStock.push(args);
    }
  }

  if (ns === "back_in_stock_inline_form") {
    if (window.OnVoardApi.backInStockInlineForm) {
      window.OnVoardApi.backInStockInlineForm(args);
    } else {
      window.OnVoardQueue.backInStockInlineForm.push(args);
    }
  }

  if (ns === "recommender") {
    if (window.OnVoardApi.recommender) {
      window.OnVoardApi.recommender(args);
    } else {
      window.OnVoardQueue.recommender.push(args);
    }
  }

  if (ns === "reperks_widget") {
    if (window.OnVoardApi.reperksWidget) {
      window.OnVoardApi.reperksWidget(args);
    } else {
      window.OnVoardQueue.reperksWidget.push(args);
    }
  }

  if (ns === "prompt") {
    if (window.OnVoardApi.prompt) {
      window.OnVoardApi.prompt(args);
    } else {
      window.OnVoardQueue.prompt.push(args);
    }
  }

  if (ns === "web_tracking") {
    if (window.OnVoardApi.webTracking) {
      window.OnVoardApi.webTracking(args);
    } else {
      window.OnVoardQueue.webTracking.push(args);
    }
  }

  if (ns === "web_tracking_shopify") {
    if (window.OnVoardApi.webTrackingShopify) {
      window.OnVoardApi.webTrackingShopify(args);
    } else {
      window.OnVoardQueue.webTrackingShopify.push(args);
    }
  }

  if (ns === "web_tracking_bigcommerce") {
    if (window.OnVoardApi.webTrackingBigCommerce) {
      window.OnVoardApi.webTrackingBigCommerce(args);
    } else {
      window.OnVoardQueue.webTrackingBigCommerce.push(args);
    }
  }

  if (ns === "web_tracking_easystore") {
    if (window.OnVoardApi.webTrackingEasyStore) {
      window.OnVoardApi.webTrackingEasyStore(args);
    } else {
      window.OnVoardQueue.webTrackingEasyStore.push(args);
    }
  }

  if (ns === "web_tracking_shopline") {
    if (window.OnVoardApi.webTrackingShopline) {
      window.OnVoardApi.webTrackingShopline(args);
    } else {
      window.OnVoardQueue.webTrackingShopline.push(args);
    }
  }

  if (ns === "web_tracking_shopware") {
    if (window.OnVoardApi.webTrackingShopware) {
      window.OnVoardApi.webTrackingShopware(args);
    } else {
      window.OnVoardQueue.webTrackingShopware.push(args);
    }
  }

  if (ns === "web_tracking_wix") {
    if (window.OnVoardApi.webTrackingWix) {
      window.OnVoardApi.webTrackingWix(args);
    } else {
      window.OnVoardQueue.webTrackingWix.push(args);
    }
  }

  if (ns === "web_tracking_woocommerce") {
    if (window.OnVoardApi.webTrackingWooCommerce) {
      window.OnVoardApi.webTrackingWooCommerce(args);
    } else {
      window.OnVoardQueue.webTrackingWooCommerce.push(args);
    }
  }
};

function start() {
  if (!window.OnVoardAddedScripts) window.OnVoardAddedScripts = {};

  // ensure only 1 loader script
  if (window.OnVoardAddedScripts.loader) return;
  window.OnVoardAddedScripts.loader = true;
  window.OnVoardComponents = {};
  window.OnVoardEventsData = {};

  // setup queues for individual apps
  window.OnVoardQueue = {};
  window.OnVoardApi = {};

  window.OnVoardQueue.reviewsWidget = [];
  window.OnVoardQueue.reperksWidget = [];
  window.OnVoardQueue.starRating = [];
  window.OnVoardQueue.aovProgressBar = [];
  window.OnVoardQueue.recommender = [];
  window.OnVoardQueue.prompt = [];
  window.OnVoardQueue.backInStock = [];
  window.OnVoardQueue.backInStockFloatingButton = [];
  window.OnVoardQueue.backInStockInlineButton = [];
  window.OnVoardQueue.backInStockInlineText = [];
  window.OnVoardQueue.backInStockInlineForm = [];
  window.OnVoardQueue.webTracking = [];
  window.OnVoardQueue.webTrackingShopify = [];
  window.OnVoardQueue.webTrackingBigCommerce = [];
  window.OnVoardQueue.webTrackingEasyStore = [];
  window.OnVoardQueue.webTrackingShopline = [];
  window.OnVoardQueue.webTrackingShopware = [];
  window.OnVoardQueue.webTrackingWix = [];
  window.OnVoardQueue.webTrackingWooCommerce = [];

  // process queued function calls
  if (window.OnVoard) {
    const queue = window.OnVoard.q || [];
    for (var i in queue) {
      process(queue[i]);
    }
  }

  // update `window.OnVoard` to process future commands immediately
  window.OnVoard = function() {
    process(arguments);
  };

  // on document ready
  // https://stackoverflow.com/a/39993724/1446284
  const onReady = () => {

    // ensure rates are loaded for bigcommerce
    if (window.OnVoardData && window.OnVoardData.ecommerce_platform === 'bigcommerce') {
      import('common/bigcommerce-utils')
      .then((bcUtils) => {
        bcUtils.executeWithRates(() => loadScripts());
      });
      return;
    }

    // shopline provision
    if (typeof Shopline !== 'undefined' && window.Shopline.merchantId) {
      import('common/shopline-utils')
      .then((shoplineUtils) => {
        shoplineUtils.provision(loadScripts);
      });
      return;
    }

    // wix provision
    if (window.OnVoardData && window.OnVoardData.ecommerce_platform === 'wix') {

      // load placement tool early
      const loadPlacementTool = checkLoadPlacementTool();
      if (loadPlacementTool) loadScripts();

      import('common/wix-utils')
      .then((wixUtils) => {
        wixUtils.provision(loadScripts);
      });
      return;
    }

    loadScripts();
  };

  if(document.readyState !== 'loading' ) {
    onReady();
  } else {
    document.addEventListener('DOMContentLoaded', (evt) => {
      onReady();
    });
  }
}



start();
